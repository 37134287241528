import React from "react"
import "./Contenu.css"
 
export default class ContenuD extends React.Component{
    render(){
        return(
            <div className="contenuD">
            <p>
                {this.props.text}
                <ul>
                    <li>{this.props.li1}</li>
                    <li>{this.props.li2}</li>
                    <li>{this.props.li3}</li>
                    <li>{this.props.li4}</li>
                </ul>
            </p>
            
            </div>

        )
    }
}