import React from "react"
import "./produit.css"

export default class Produit extends React.Component{


    render(){

        return(
            <div className="produitItem">
                <img src={this.props.source} alt="Produit" style={{width: "100%" }}/>
                <div className="containerP">
                    <h4>{this.props.nomP}</h4>
                    <p>{this.props.descP}</p>
                </div>
            </div>
        )

    }
}