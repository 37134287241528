import React from "react"
import Titre from "./../Titres/Titre"
import Contenu from "./../Contenu/Contenu"

export default function AboutUs(props){
    return(
        <div id={props.id}>
            <Titre title="Qui Sommes Nous ?"/>
             <Contenu text="La société SOLAR AZIMUT, est une société à responsabilité limitée nouvellement créée et ayant pour activité principale la commercialisation des équipements hydrauliques, hydromécaniques et électroniques d’origine européenne en Tunisie et au Nord africain"/>


        </div>

    )
}