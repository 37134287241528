import React from "react"
import Titre from "./../Titres/Titre"
import ContenuD from "./../Contenu/ContenuD"

export default function Domaines(){
    return(
        <div>
    <Titre title="domaines d’intervention"/>
    <ContenuD text="Les  principaux domaines d’intervention  de SOLAR AZIMUT sont : " li1="les équipements d’électricité et d’énergies renouvelables" li2="Les équipements hydrauliques" li3="L’automatisme et la télégestion " li4="Télésurveillance"/>
    </div>
    )
}