import React from "react"
import "./Contenu.css"
 
export default class Contenu extends React.Component{
    render(){
        return(
            <div className="contenu">
            <p>
                {this.props.text}
            </p>
            
            </div>

        )
    }
}