import React from "react"
import "./Contact.css"

export default function Contact(){

    return(
        <div className="Contact" id="Contact">
            <table>
                <tr className="tHeader">
                    <td>Adresse</td>
                    <td>Telephone</td>
                    <td>Email</td>
                </tr>
                <tr className="tContenT">
                    <td>20 Avenue Habib Bourguiba bureau B2</td>
                    <td>Telephone : +216 71 425 452</td>
                    <td>grh.solarazilut@gmail.com</td>
               </tr>
                <tr className="tContenT">
                    <td>2033 Megrine Tunisie B.P 168</td>
                    <td>Fax : +216 71 425 448</td>
                    {/* <td>nafkha.m.youssef@gmail.com</td> */}
                </tr>
            </table>
        </div>
    )

}