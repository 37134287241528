import React from "react"
import "./Navbar.css"

export default function NavBar(){
    return(
        <header>
            <div className="container">
                <nav>
                    <h1 className="Brand"><a href="index.html">Solar <span>Azimut</span></a></h1>
                    <ul>
                        {/* <li><a href="#">acceuil</a></li> */}
                        <li><a href="#info">Qui Sommes nous</a></li>
                        <li><a href="#partners">Partenaires</a></li>
                        <li><a href="#produits">Produits</a></li>
                        <li><a href="#Contact">Contact</a></li>
                    </ul>
                </nav>
            </div>
            <div className="logo">
                {/* <img src="https://upload.wikimedia.org/wikipedia/fr/6/6b/EST_New_Logo_2012.png" alt="Logo"/> */}
                <h1 style={{color : "white"}}>Bienvenue au Solar Azimut</h1>
                {/* <h1>Esperance Sportive de Tunis</h1> */}
            </div>
        </header>
    )
}