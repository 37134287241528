import React from "react"
import "./Titre.css"

export default function Titre(props){
    return(
        <div className="Title">
        <h1 className="Titre">{props.title}</h1>
    </div>

    )
}