import React from "react"
import Titre from "./../Titres/Titre"
import Produit from "./produit"

export default class Produits extends React.Component{

    render(){
        return(
            <div id="produits">
                <Titre title="Nos Produits"/>
                <div className="listeP">
                <Produit source="https://images.unsplash.com/photo-1553341640-6b28ff92098a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80" nomP="Télégestion" descP="Data acquisition and automation systems"/>
                <Produit source="https://cms.bconsole.com/image/0/0/1518100090-categoriesgamma-motori.png/fb-pompe" nomP="Pompes" descP="Vente groupe immergées et horizontaux et accessoires"/>
                <Produit source="https://images.unsplash.com/photo-1496368077930-c1e31b4e5b44?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80" nomP="Télésurveillance" descP="Surveiallance electronique par cameras et radar"/>
                <Produit source="https://images.unsplash.com/photo-1545209463-e2825498edbf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1867&q=80" nomP="Photovoltaïque" descP="vente de panneaux solaires "/>
                </div>
                {/* <button className="btn">Montre Plus</button> */}
            </div>
        )
    }

} 