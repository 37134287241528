import React from "react"
import Titre from "./../Titres/Titre"
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Container from "react-bootstrap/Container"
// import Row from "react-bootstrap/Row"
// import Col from "react-bootstrap/Col"
import "./Partenaires.css"
export default function Partenaires(){
    return(
        <div className="Partners" id="partners">
            <Titre title="Nos Partenaires"/>
            {/* <Container>
                <Row>
                    <Col lg="3">
                        <h1>1</h1>

                    </Col>
                    <Col lg="3">
                        <h1>2</h1>

                    </Col>
                    <Col lg="3">
                        <h1>3</h1>

                    </Col>
                    <Col lg="3">
                        <h1>4</h1>

                    </Col>


                </Row>

            </Container> */}
            <table className="center">
                <tr>
                    <td><a href="https://fbpompe.com/"><img  src="https://fbpompe.com/images/logo-fbpompe.png"   alt="logo"/></a></td>
                    <td><a href="http://seneca.it"><img src="http://www.seneca.it/assets/images/seneca.png" alt="logo" /></a> </td>
                    <td><a href="https://www.futurasun.com/en/"><img src="https://www.futurasun.com/wp-content/uploads/2017/11/logo-main-piccolo.png" alt="logo" /></a></td>
                    <td><a href="http://cgmitalia.it"><img src="http://cgmitalia.it/cgm_italia_gruppi_elettrogeni/img/cgm_logo.jpg" alt="logo" /></a></td>
                </tr>

            </table>
            <table className="center">
                <tr>
                    <td><a href="https://idrogasengineering.it"><img src="https://www.idrogasengineering.it/wp-content/uploads/2019/04/logo.png"  alt="logo"/></a></td>
                    <td><a href="http://Bmeters.com"><img src="https://www.bmeters.com/wp-content/themes/bmeters/images/logo-bmeters.png" alt="logo" /></a></td>
                    <td><a href="http://italianawp.com"><img src="http://italianawp.com/img/logo_italiana_screen.jpg" alt="logo" /></a></td>
                    <td><a href="https://www.tecnotergroup.com/?lang=en" style={{border : '3px solid white;'}}><img src="https://www.tecnotergroup.com/wp-content/uploads/2019/06/logo.png" alt="logo"/></a></td>
                </tr>

            </table>

        </div>
    )
}