import React from "react"
import Titre from "./../Titres/Titre"
import Contenu from "./../Contenu/Contenu"
import "./Motivation.css"

export default function Motivation(){
    return(
        <div>
        <Titre title="Pourquoi Nous Choisir ?"/>
        {/* <Contenu text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris augue neque gravida in fermentum et. Sapien faucibus et molestie ac. Libero enim sed faucibus turpis in eu mi bibendum neque. Tortor id aliquet lectus proin. Turpis egestas pretium aenean pharetra magna. Felis imperdiet proin fermentum leo vel orci porta non pulvinar. Vulputate ut pharetra sit amet aliquam id. Maecenas ultricies mi eget mauris pharetra et ultrices. Urna condimentum mattis pellentesque id nibh. Eget lorem dolor sed viverra ipsum. Purus in massa tempor nec feugiat nisl. Vulputate sapien nec sagittis aliquam malesuada bibendum."/> */}
        <div className="Avantages">
            <table className="center">
                <tr className="tHead">
                    <td>Rapidité</td>
                    <td>Qualité</td>
                    <td>Prix</td>
                </tr>
                <tr className="tContent">
                    <td>Notre service est rapide</td>
                    <td>La qualité de nos produits est garantie</td>
                    <td>Les prix de nos produits sont raisonnables</td>
                </tr>

            </table>

        </div>
    </div>


    )

}