import React from "react"
import NavBar from "./Components/Navbar/Navbar"
import AboutUs from "./Components/AboutUs/AboutUs"
import Motivation from "./Components/Motivation/Motivation"
import Partenaires from "./Components/Partenaires/Partenaires"
import Produits from "./Components/Produits/Produits"
import Contact from "./Components/Contact/Contact"
import Domaines from "./Components/Domaines/Domaines"

export default function App()
{
    return(
        <div>
        <NavBar/>
        <AboutUs id="info"/>
        <Domaines />
        <Motivation/>
        <Partenaires/>
        <Produits/>
        <Contact/>

        </div>
    )

}